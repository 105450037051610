import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle, isDark }) => {
  if (isDark) {
    return <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link className="mx-auto" to="/">
                <span className="sr-only">Bergset AS</span>
                <StaticImage placeholder="none" width={200} className="h-10 w-auto" src="../images/logo2/bg-logo-full-negative.png" alt="Bergset AS" />
              </Link>
              {/*<div className="-mr-2 flex items-center md:hidden">*/}
              {/*  <button type="button"*/}
              {/*          className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"*/}
              {/*          aria-expanded="false">*/}
              {/*    <span className="sr-only">Open main menu</span>*/}
              {/*    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
              {/*         stroke="currentColor" aria-hidden="true">*/}
              {/*      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>*/}
              {/*    </svg>*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="hidden md:flex md:space-x-10">
            <Link to="#about" className="font-medium text-gray-300 hover:text-primary">Om oss</Link>

            <Link to="#services" className="font-medium text-gray-300 hover:text-primary">Tjenester</Link>

            <Link to="#cases" className="font-medium text-gray-300 hover:text-primary">Leveranser</Link>
          </div>
          <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
          <span className="inline-flex rounded-md shadow">
            <Link to="#contact"
               className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md bg-primary hover:bg-primary-dark">
              Kontakt oss
            </Link>
          </span>
          </div>
        </nav>
      </div>
      {/*<div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">*/}
      {/*  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">*/}
      {/*    <div className="px-5 pt-4 flex items-center justify-between">*/}
      {/*      <div>*/}
      {/*        <Img className="h-8 w-auto" fixed={smallLogoDark.childImageSharp.fixed} alt="" />*/}
      {/*      </div>*/}
      {/*      <div className="-mr-2">*/}
      {/*        <button type="button"*/}
      {/*                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">*/}
      {/*          <span className="sr-only">Close menu</span>*/}
      {/*          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
      {/*               stroke="currentColor" aria-hidden="true">*/}
      {/*            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>*/}
      {/*          </svg>*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="px-2 pt-2 pb-3">*/}
      {/*      <Link to="/#about"*/}
      {/*         className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-gray-900 hover:bg-gray-50">Om oss</Link>*/}

      {/*      <Link to="/#services"*/}
      {/*         className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-gray-900 hover:bg-gray-50">Tjenester</Link>*/}

      {/*      <Link to="/#cases"*/}
      {/*         className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-gray-900 hover:bg-gray-50">Leveranser</Link>*/}
      {/*    </div>*/}
      {/*    <Link to="/#contact"*/}
      {/*       className="block w-full px-5 py-3 text-center font-medium bg-gray-50 hover:bg-gray-100">*/}
      {/*      Kontakt oss*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  } else {
    return <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link className="mx-auto" to="/">
                <span className="sr-only">Bergset AS</span>
                <StaticImage placeholder="none" width={200} className="h-10 w-auto" src="../images/logo2/bg-logo-full.png" alt="Bergset AS" />
              </Link>
              {/*<div className="-mr-2 flex items-center md:hidden">*/}
              {/*  <button type="button"*/}
              {/*          className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"*/}
              {/*          aria-expanded="false">*/}
              {/*    <span className="sr-only">Open main menu</span>*/}
              {/*    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
              {/*         stroke="currentColor" aria-hidden="true">*/}
              {/*      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>*/}
              {/*    </svg>*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="hidden md:flex md:space-x-10">
            <Link to="/#about" className="font-medium text-gray-500 hover:text-gray-900">Om oss</Link>

            <Link to="/#services" className="font-medium text-gray-500 hover:text-gray-900">Tjenester</Link>

            <Link to="/#cases" className="font-medium text-gray-500 hover:text-gray-900">Leveranser</Link>
          </div>
          <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
          <span className="inline-flex rounded-md shadow">
            <Link to="/#contact"
               className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md bg-primary hover:bg-primary-dark">
              Kontakt oss
            </Link>
          </span>
          </div>
        </nav>
      </div>
      {/*<div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">*/}
      {/*  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">*/}
      {/*    <div className="px-5 pt-4 flex items-center justify-between">*/}
      {/*      <div>*/}
      {/*        <GatsbyImage className="h-8 w-auto" image={smallLogo.childImageSharp.gatsbyImageData} alt="" />*/}
      {/*      </div>*/}
      {/*      <div className="-mr-2">*/}
      {/*        <button type="button"*/}
      {/*                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">*/}
      {/*          <span className="sr-only">Close menu</span>*/}
      {/*          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
      {/*               stroke="currentColor" aria-hidden="true">*/}
      {/*            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>*/}
      {/*          </svg>*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="px-2 pt-2 pb-3">*/}
      {/*      <Link to="#about"*/}
      {/*         className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Om oss</Link>*/}

      {/*      <Link to="#services"*/}
      {/*         className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Tjenester</Link>*/}

      {/*      <Link to="#cases"*/}
      {/*         className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Leveranser</Link>*/}
      {/*    </div>*/}
      {/*    <Link to="#contact"*/}
      {/*       className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">*/}
      {/*      Kontakt oss*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

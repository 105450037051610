import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Footer = () => {
  return <div id="footer" className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
    <div className="hidden lg:block py-16 px-4 sm:px-6 lg:col-span-3 lg:px-8 lg:py-24 xl:pr-12">
      <StaticImage
        placeholder="none"
        width={300}
        src="../images/logo2/bg-logo-negative.png"
        alt="Bergset AS"
      />
    </div>
  <div className="text-center sm:text-left py-6 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-12 xl:pr-12">
    <div className="space-y-12">
      {/*<p className="mt-3 text-lg leading-6 text-gray-200">*/}
      {/*  Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus*/}
      {/*  arcu.*/}
      {/*</p>*/}
      <dl className="mt-8 text-base">
        <div>
          <p className="text-primary py-2 text-xl">Hovedkontor</p>
          <dt className="sr-only">Adresse</dt>
          <dd>
            <p>Eidsgata 3B</p>
            <p>6770 Nordfjordeid</p>
          </dd>
          <br />
          <p className="text-primary py-2 text-xl">Avdelingskontor</p>
          <dt className="sr-only">Adresse</dt>
          <dd>
            <p>Hornneskletten 4</p>
            <p>6809 Førde</p>
          </dd>
        </div>
        <div className="mt-6">
          <p className="text-primary py-2 text-xl">Kontaktinformasjon</p>
          <dt className="sr-only">Telefon</dt>
          <dd className="justify-center sm:justify-start flex">
            <svg className="flex-shrink-0 h-6 w-6 text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
            </svg>
            <span className="ml-3">
                +47 952 29 160
              </span>
          </dd>
        </div>
        <div className="mt-3">
          <dt className="sr-only">E-post</dt>
          <dd className="justify-center sm:justify-start flex">
            <svg className="flex-shrink-0 h-6 w-6 text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
            </svg>
            <span className="ml-3">
              <a className="hover:text-primary" href="mailto:post@bergset.no">post@bergset.no</a>
            </span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
  </div>
}